/* Custom scrollbar styling */
.sidebarscroll {
  scrollbar-width: thin;
  scrollbar-color: #1F1F1F #08090A; /* Green with 20% opacity over dark bg */
}

.sidebarscroll::-webkit-scrollbar {
  width: 4px;
  background: #1F1F1F;
}

.sidebarscroll::-webkit-scrollbar-track {
  background: #1F1F1F;
  border-radius: 2px;
}

.sidebarscroll::-webkit-scrollbar-thumb {
  background-color: #1F1F1F; /* 20% opacity */
  border-radius: 2px;
}

.sidebarscroll::-webkit-scrollbar-thumb:hover {
  background-color: #1F1F1F; /* 40% opacity on hover */
} 