/* Container for toasts */
.toast-container {
  position: fixed;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 300px;
  padding: 10px;
}

/******** Toast base styles *********/
.toast {
  padding: 12px 16px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  text-align: left;
  padding: 12px;
  gap: 8px;
  width: 26.875rem;
  min-height: 6.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.toast-content {
  display: flex;
  gap: 0.75rem;
  height: 100%;
  position: relative;
  min-height: 4rem;
}

.toast-icon-container {
  display: flex;
  align-items: top;
  justify-content: top;
  height: 100%;
  min-width: 1.5rem;
}

.toast-icon {
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1/1;
}

.toast-text-container {
  /* title + message + buttons*/
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  gap: 0.5rem;
  flex: 1;
  min-height: 4rem;
}

.toast-title {
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.1875rem;
}

.toast-message {
  align-self: stretch;
  text-align: left;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.0625rem;
  white-space: normal;
  word-wrap: break-word;
}

/*********** Buttons in toast ***********/
.toast-action-buttons-container {
  display: flex;
  gap: 0.5rem;
}

.toast-dismiss-button {
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 0.875rem;
  padding: 0;
  text-align: left;
  width: fit-content;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.0625rem;
}

.toast-action-button {
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 0.875rem;
  padding: 0;
  text-align: left;
  width: fit-content;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.0625rem;
  text-decoration-line: underline;
  text-underline-offset: auto;
  text-underline-position: from-font;
}

.toast-close {
  background: none;
  border: none;
  color: #CCCCCC;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.25rem;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  transition: background-color 0.2s ease;
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
}

.toast-close:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/******** Success toast *********/
.toast-success {
  background-color: #013124;
  border: 2px solid #0BB185;
}

.toast-success-icon {
  background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="check-circle-2 / 16 / White"><path id="Vector" d="M9 12L11 14L15 10M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="%2334D399" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></g></svg>');
}

.toast-success-title {
  color: var(--Success-400, #34D399);
}

.toast-success-message {
  color: var(--Success-500, #10B981);
}

.toast-success-dismiss-button {
  color: var(--Success-500, #10B981);
}

.toast-success-action-button {
  color: var(--Success-400, #34D399);
}

/******** Error toast *********/
.toast-error {
  background-color: #380202;
  border: 2px solid #C32929;
}

.toast-error-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M12 8V12M12 16H12.01M7.86 2H16.14L22 7.86V16.14L16.14 22H7.86L2 16.14V7.86L7.86 2Z" stroke="%23EF4444" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>')
}

.toast-error-title {
  color: var(--Error-400, #EF4444);
}

.toast-error-message {
  color: var(--Error-500, #F87171);
}

.toast-error-dismiss-button {
  color: var(--Error-500, #EF4444);
}

.toast-error-action-button {
  color: var(--Error-400, #EF4444);
}

/******** Warning toast *********/
.toast-warning {
  background: #2a2600;
  border: 2px solid var(--Warning-600, #D97706);
}

.toast-warning-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M12 9V13M12 17H12.01M21.7299 18L13.7299 3.99998C13.5555 3.69218 13.3025 3.43617 12.9969 3.25805C12.6912 3.07993 12.3437 2.98608 11.9899 2.98608C11.6361 2.98608 11.2887 3.07993 10.983 3.25805C10.6773 3.43617 10.4244 3.69218 10.2499 3.99998L2.24993 18C2.07361 18.3053 1.98116 18.6519 1.98194 19.0045C1.98272 19.3571 2.07671 19.7032 2.25438 20.0078C2.43204 20.3124 2.68708 20.5646 2.99362 20.7388C3.30017 20.9131 3.64734 21.0032 3.99993 21H19.9999C20.3508 20.9996 20.6955 20.9069 20.9992 20.7313C21.303 20.5556 21.5551 20.3031 21.7304 19.9991C21.9057 19.6951 21.998 19.3504 21.9979 18.9995C21.9978 18.6486 21.9054 18.3039 21.7299 18Z" stroke="%23F59E0B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>')
}

.toast-warning-title {
  color: var(--Warning-400, #F59E0B);
}

.toast-warning-message {
  color: var(--Warning-500, #FCD34D);
}

.toast-warning-dismiss-button {
  color: var(--Warning-500, #D97706);
}

.toast-warning-action-button {
  color: var(--Warning-400, #F59E0B);
}

/******** Info toast *********/
.toast-info {
  border-radius: 0.75rem;
  border: 2px solid var(--Secondary-600, #57AEFF);
  background: rgba(0, 44, 85);
}

.toast-info-icon {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M12 8V12M12 16H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="%23ACD7FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>')
}

.toast-info-title {
  color: var(--Secondary-500, #ACD7FF);
}

.toast-info-message {
  color: var(--Secondary-600, #57AEFF);
}

.toast-info-dismiss-button {
  color: var(--Secondary-600, #57AEFF);
}

.toast-info-action-button {
  color: var(--Secondary-500, #ACD7FF);
}


/* Positioning styles */
.toast-top-right {
  top: 10px;
  right: 10px;
  position: fixed;
}

.toast-top-left {
  top: 10px;
  left: 10px;
  position: fixed;
}

.toast-bottom-right {
  bottom: 10px;
  right: 10px;
  position: fixed;
}

.toast-bottom-left {
  bottom: 10px;
  left: 10px;
  position: fixed;
}

.toast-top-center {
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
}

.toast-bottom-center {
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
}

/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}